import React from 'react'
const Section_header = () => {
  return (
    <header id="header" className="d-flex align-items-center ">
      <div className="container-fluid d-flex align-items-center justify-content-lg-between">
        <a href="index.html" className="logo me-auto me-lg-0"><img src={process.env.PUBLIC_URL + "assets/img/logo.png"} alt="" className="img-fluid" /></a>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li><a className="nav-link scrollto active" href="#kinerja_daerah">Kinerja Daerah</a></li>
            <li><a className="nav-link scrollto" href="#statistik">Data Statistik</a></li>
            <li><a className="nav-link scrollto" href="#edatabase">e-Database</a></li>
            <li><a className="nav-link scrollto" href="https://webapps.bps.go.id/sulut/fordone/site/login" target={"_blank"} rel="noreferrer">FDONE Sulut</a></li>
            <li><a className="nav-link scrollto" href="https://satudata.go.id" target={"_blank"} rel="noreferrer">Satu Data Indonesia</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>

        <div className="header-social-links d-flex align-items-center">
          <a href="#!" className="twitter"><i className="bi bi-twitter"></i></a>
          <a href="#!" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="#!" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="#!" className="linkedin"><i className="bi bi-linkedin"></i></a>
        </div>

      </div>
    </header>
  )
}

export default Section_header