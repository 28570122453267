import React from 'react'

const Section_footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-lg-6">
              <p>KOTAMOBAGU SATU DATA</p>
            </div>
          </div>

          <div className="row footer-newsletter justify-content-center">
            <div className="col-lg-6">
              <h4>Kunjungi kami di</h4>
            </div>
          </div>

          <div className="social-links">
            <a href="#!" className="twitter"><i className="bx bxl-twitter"></i></a>
            <a href="#!" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="#!" className="instagram"><i className="bx bxl-instagram"></i></a>
            <a href="#!" className="google-plus"><i className="bx bxl-skype"></i></a>
            <a href="#!" className="linkedin"><i className="bx bxl-linkedin"></i></a>
          </div>

        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright <strong><span>Pemerintah Kota Kotamobagu</span></strong>. All Rights Reserved
        </div>
        <div className="credits">

          Didukung Oleh Dinas Komunikasi dan Informatika Kotamobagu<br />
          Desain Template Oleh <a href="https://bootstrapmade.com/">BootstrapMade</a>

        </div>

      </div>
    </footer>
  )
}

export default Section_footer