import React from 'react'
import { BsArrowLeftCircleFill, BsArrowRightCircleFill, BsFillFileEarmarkBarGraphFill } from "react-icons/bs";
import { HostContext } from '../../context';

const Section_beranda = () => {

  const { HOST } = React.useContext(HostContext)
  const [data, set_data] = React.useState([])
  const handleDetail = (id) => {

  }

  const ItemIndex = ({ handleDetail, title, tahun, data }) => (
    <div className="col-md-4 col-sm-12 mb-1">
      <div className="indikator-box shadow-lg p-2 mb-2" onClick={handleDetail}>
        <p className='title'>{title}</p>
        <div className='d-flex justify-content-between'>
          <div className="box-body d-flex">
            <p className='data-tahun'>Tahun {tahun[tahun.length - 2]} <br /> <strong>{data[data.length - 2] ? data[data.length - 2] : '-'}</strong></p>
            <p className='data-tahun'>Tahun {tahun[tahun.length - 1]} <br /> <strong>{data[data.length - 1] ? data[data.length - 1] : '-'}</strong></p>
          </div>
          <div className="box-icon">
            <BsFillFileEarmarkBarGraphFill size={62} className="icon" />
          </div>
        </div>
      </div>
    </div>
  )

  React.useEffect(() => {
    const getData = async () => {
      try {
        const requ = await fetch(`${HOST}/api/kinerja_daerah/ambil`, { method: 'GET' })
        const resu = await requ.json()
        if (resu.data) {
          set_data(resu.data)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
    getData()
  }, [HOST])

  return (
    <section id="kinerja_daerah" className="kinerja_daerah">
      <div className="container">
        <div className="section-title">
          <h2 className="text-dark">Kinerja Daerah Kotamobagu</h2>
        </div>
        <div className="row">
          <div className="col text-left">
            <button className="btn mb-3 mr-1 btn-caraousel" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <BsArrowLeftCircleFill />
            </button>
            <button className="btn mb-3 btn-caraousel" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <BsArrowRightCircleFill />
            </button>
          </div>
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-item active">
              <div className="row">
                {data.map((v, i) => {
                  return (
                    i <= 2 ? <ItemIndex key={i} title={v.index_title} tahun={v.index_tahun} data={v.index_data} handleDetail={() => handleDetail(i)} /> : ''
                  )
                })}
              </div>
            </div>

            <div className="carousel-item">
              <div className="row">
                {data.map((v, i) => {
                  return (
                    i >= 3 && i <= 5 ? <ItemIndex key={i} title={v.index_title} tahun={v.index_tahun} data={v.index_data} handleDetail={() => handleDetail(i)} /> : ''
                  )
                })}
              </div>
            </div>

            <div className="carousel-item">
              <div className="row">
                {data.map((v, i) => {
                  return (
                    i >= 6 && i <= 8 ? <ItemIndex key={i} title={v.index_title} tahun={v.index_tahun} data={v.index_data} handleDetail={() => handleDetail(i)} /> : ''
                  )
                })}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section_beranda