import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const DATA_PAGINATION = ({ keyword, itemsPerPage, items, type, tahun }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);


  useEffect(() => {
    if (keyword.length > 2) {
      const _items = items.filter((item) => {
        return item.namaindikator.toLowerCase().match(keyword.toLowerCase())
      });
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(_items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(_items.length / itemsPerPage));
    } else {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }
  }, [keyword, itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    if (keyword.length > 2) {
      const _items = items.filter((item) => {
        return item.namaindikator.toLowerCase().match(keyword.toLowerCase())
      });
      const newOffset = event.selected * itemsPerPage % _items.length;
      setItemOffset(newOffset);
    } else {
      const newOffset = event.selected * itemsPerPage % items.length;
      setItemOffset(newOffset);
    }
  };


  return (
    <>
      <div className="table-responsive">
        <table className='table table-bordered table-striped bg-light table-hover shadow-sm' cellSpacing={0} cellPadding={5} style={{ fontSize: "12px" }}>
          <thead className={"bg-thead-view text-light"}>
            <tr>
              <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
              <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "middle" }}>Indikator</th>
              <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "middle" }}>Satuan</th>
              <th colSpan={tahun.length} className={"text-center"}>Data</th>
            </tr>
            <tr>
              {
                type === "type_iku"
                  ?
                  tahun.map((v) => {
                    return v === "x021" ? <th key={v}>2021</th> : <th key={v}>{v}</th>
                  })
                  :
                  tahun.map((v) => {
                    return <th key={v}>{v}</th>
                  })
              }
            </tr>
          </thead>
          <tbody>
            {
              currentItems.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{v.namaindikator}</td>
                    <td>{v.satuan}</td>
                    {
                      tahun.map((vv) => {
                        return (
                          <td key={vv}>
                            {
                              v.data.filter((val) => { return val.tahun === vv }).length ? v.data.filter((val) => { return val.tahun === vv })[0].value ? v.data.filter((val) => { return val.tahun === vv })[0].value : 0 : 0
                            }
                          </td>)
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="col-md-12">
        <ReactPaginate
          nextLabel={<BsArrowRightCircleFill />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<BsArrowLeftCircleFill />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          act
          // activeClassName={"active"}
          activeLinkClassName={"pgActive"}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  )
}

export default DATA_PAGINATION