import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import LOGIN from "../pages/admin/login";
import PUBLIK_UI from "../pages/public"
import DASHBOARD from "../pages/admin/dashboard";
import KINERJA_DAERAH from "../pages/admin/kinerja_daerah";



const Index = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<PUBLIK_UI />}></Route>
        <Route path="/login" element={<LOGIN />}></Route>
        <Route path="/beranda" element={<Pages mod={<DASHBOARD />} />}></Route>
        <Route path="/webservis">
          <Route path="kinerja_daerah" element={<Pages mod={<KINERJA_DAERAH />} />} />
        </Route>
      </Routes>
    </>
  )
}

export default Index;