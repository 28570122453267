import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HostContext } from './context';
import PagesInRoute from './routes'
const App = () => {
  return (
    <>
      <Router basename='/'>
        <PagesInRoute />
      </Router>
    </>
  )
}

// const HOST = "http://localhost:8080";
const HOST = "/webservices/index.php";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HostContext.Provider value={{ HOST }}>
      <App />
    </HostContext.Provider>
  </React.StrictMode>
);