import React from 'react'
import SECTION_HEADER from './section_header';
import SECTION_HERO from './section_hero';
import SECTION_KINERJA from './section_kinerja';
import SECTION_STATISTIK from './section_statistik';
import SECTION_EDATABASE from './section_edatabase';
import SECTION_FOOTER from './section_footer';

const index = () => {
  return (
    <>
      <SECTION_HERO />
      <SECTION_HEADER />
      <main id='main'>
        <SECTION_KINERJA />
      </main>
      <SECTION_STATISTIK />
      <SECTION_EDATABASE />
      <SECTION_FOOTER />
    </>
  )
}

export default index