import React from 'react'
import { HostContext } from '../../context';

const Kinerja_daerah = () => {
  const tahun = [2017, 2018, 2019, 2020, 2021];
  const index = [
    "Indeks SPBE",
    "Indeks Kepatuhan Pelayanan Publik",
    "Indeks Pengukuran Keuangan Daerah (IPKD)",
    "Indeks Reformasi Birokrasi",
    "Indeks Pembangunan Manusia",
    "Indeks Demokrasi",
    "Indeks Pembangunan Gender (IPG)",
    "Indeks Kualitas Lingkungan Hidup",
    "Indeks Kinerja Sistem Infrastruktur",
  ];

  const [data, set_data] = React.useState([])

  const formRel = React.useRef()

  const { HOST } = React.useContext(HostContext)

  React.useEffect(() => {
    const getData = async () => {
      try {
        const requ = await fetch(`${HOST}/api/kinerja_daerah/ambil`, { method: 'GET' })
        const resu = await requ.json()
        if (resu.data) {
          set_data(resu.data)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
    getData()
  }, [HOST])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(formRel.current);
    const requ = await fetch(`${HOST}/api/kinerja_daerah/buat`, { method: 'POST', body: data })
    try {
      const resu = await requ.json()
      console.log(resu)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="row mt-5">
      <div className="col-md-12 ">
        <div className="card">
          <div className="card-header">
            KINERJA DAERAH
          </div>
          <form onSubmit={handleSubmit} ref={formRel}>
            <div className="card-body">
              <table className='table table-striped table-bordered table-hover' cellPadding={8} cellSpacing={0}>
                <thead>
                  <tr>
                    <th rowSpan={2}>No</th>
                    <th rowSpan={2}>Index Kinerja Daerah</th>
                    <th colSpan={tahun.length} className={"text-center"}>Data</th>
                  </tr>
                  <tr>
                    {tahun.map(v => { return <th key={v}>{v}</th> })}
                  </tr>
                </thead>
                <tbody>
                  {
                    data.length === 0
                      ?
                      index.map((vi, ii) => {
                        return <tr key={ii}>
                          <td>{ii + 1}</td>
                          <td>{vi}</td>
                          {tahun.map((vt, it) => {
                            return <td key={it}><input name={`${vi}[${vt}]`} type={"text"} placeholder={"-"} className={"form-control"} value={this} onChange={e=>set_data([])}/></td>
                          })}
                        </tr>
                      })
                      :
                      data.map((vi, ii) => {
                        return <tr key={ii}>
                          <td>{ii + 1}</td>
                          <td>{vi.index_title}</td>
                          {tahun.map((vt, it) => {
                            return <td key={it}><input name={`${vi}[${vt}]`} type={"text"} placeholder={"-"} className={"form-control"} value={vi.index_data[it]} onChange={e => set_data([])} /></td>
                          })}
                        </tr>
                      })
                  }

                </tbody>
              </table>
              <button type={"submit"} className={"btn btn-primary"}>Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Kinerja_daerah