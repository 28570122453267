import React from 'react'

const Login = () => {

  const style = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // background: '#f7f7f7'
    },
    form_login: {
      width: '450px',
      background: '#fff',
      padding: '25px 25px',
      boxShadow: `0 0 128px 0 rgba(0, 0, 0, 0.1),
                  0 32px 64px -48px rgba(0, 0, 0, 0.5)`
    },
    form_login_header: {
      display:'flex',
      fontSize: '25px',
      fontWeight: '600',
      paddingBottom: "10px",
      borderBottom: "'1px solid #e6e6e6"
    },
    form_login_header_span : {
      textalign:'center'
    }
  }

  const handleLogin = async ()=>{
    
  }

  return (
    <section id='login' style={style.wrapper}>
      <div style={style.form_login}>
        <header style={style.form_login_header}>
          <span style={style.form_login_header_span}>Masuk Pengguna</span>
        </header>
        <form action="#">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type={"text"} placeholder={"Masukan email..."} className={"form-control"} id={"email"} autoComplete={"off"} />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="sandi">Sandi:</label>
            <input type={"password"} placeholder={"Masukan sandi..."} className={"form-control"} id={"sandi"} autoComplete={"off"} />
          </div>
          <div className="form-group mt-1">
            <button className='btn btn-primary' type={"submit"}>Masuk</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Login