const BRS_tanggalIndo = (str) => {
  const hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum\'at', 'Sabtu']
  const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
  let newTanggal;
  const date = new Date(str);
  const day = date.getDay()
  const formatTanggal = date.toLocaleDateString('id-ID');
  const tanggal = formatTanggal.split('/')
  newTanggal = `${hari[day]}, ${tanggal[0]}/${bulan[tanggal[1]]}/${tanggal[2]}`;
  return newTanggal
}

const BRS_formatRupiah = (angka, prefix) => {
  let number_string = angka.replace(/[^,\d]/g, '').toString(),

    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}



export { BRS_tanggalIndo, BRS_formatRupiah }