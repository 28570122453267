import React from 'react'

const Section_hero = () => {
  return (
    <section id='hero'>
      <div className="hero-container">
        <img src="./assets/img/logo.png" width="10%" className="my-3" alt="logo" />
        <h1>KOTAMOBAGU SATU DATA</h1>
        <h2>Menuju kesatuan dan keutuhan data</h2>
        <a href="#kinerja_daerah" className="btn-get-started scrollto">Lihat Data</a>
      </div>
    </section>
  )
}

export default Section_hero