import React from 'react'
import { Link } from 'react-router-dom'
const Pages = ({ mod }) => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-primary">
        <div className="container">
          <Link className="navbar-brand" href="#" to="/">Webservis</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/beranda">Beranda</Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/webservis/kinerja_daerah">Kinerja Daerah</Link>
              </li>
            </ul>
            <span className="navbar-text text-light">
              SATU DATA KOTAMOBAGU
            </span>
          </div>
        </div>
      </nav>
      <main>
        <div className="container">
          {mod}
        </div>
      </main>
    </>

  )
}

export default Pages