import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="card">
          <div className="card-body">
            <p className='m-0 p-0'>Selamat datang di dashboard Webservis API Kotamobagu Satu Data</p>
            <small className='text-muted'>created by: Bobi R. Simbala</small>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard